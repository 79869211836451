import image1 from './1.jpg'
import image2 from './2.jpg'
import image3 from './3.jpg'
import image4 from './4.jpg'
import image5 from './2.jpg'
import image6 from './3.jpg'
import image7 from './4.jpg'

export default [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
]