import image1 from './1.jpg'
import image2 from './2.jpg'
import image3 from './3.png'
import image4 from './4.png'
import image5 from './5.png'
import image6 from './6.png'
import image7 from './7.png'
import image8 from './8.png'
import image9 from './9.png'
import image10 from './10.png'

export default [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
]