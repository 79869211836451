import image1 from './1.jpg'
import image2 from './2.jpg'
import image3 from './3.jpg'
import image4 from './4.jpg'

export default [
  image1,
  image2,
  image3,
  image4,
]