import image1 from './1.png'
import image2 from './2.png'
import image3 from './3.png'
import image4 from './4.png'

export default [
  image1,
  image2,
  image3,
  image4,
]