import images1 from './1'
import images2 from './2'
import images3 from './3'
import images4 from './4'
import images5 from './5'
import images6 from './6'
import images7 from './7'
import images8 from './8'
import images9 from './9'
import images10 from './10'
import images11 from './11'
import images12 from './12'
import images13 from './13'
import images14 from './14'
import images15 from './15'
import images16 from './16'
import images17 from './17'

export default {
  1: images1,
  2: images2,
  3: images3,
  4: images4,
  5: images5,
  6: images6,
  7: images7,
  8: images8,
  9: images9,
  10: images10,
  11: images11,
  12: images12,
  13: images13,
  14: images14,
  15: images15,
  16: images16,
  17: images17,
}